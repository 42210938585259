import React, { Component } from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import get from 'lodash/get';
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper';
import { getFormikErrors } from '../../services/formHelper';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import { PrimaryButton } from '../../stateless_components/buttons/PrimaryButton';
import { ErrorMessage } from '../../stateless_components/inputs/elements/ErrorMessage';
import { dateFormatter } from '../../services/dateFormatUtils';
import InputLabelHint from '../InputLabelHint';

const t = (key, props = {}) => I18n.t(`object.${key}`, props);

export const StyledLink = withStyles({
  root: {
    '&&': {
      color: '#007bff',
      margin: 'auto',
      textAlign: 'center',
    },
    '&&:focus': { outline: 'none' },
    '@media (max-width: 991px)': {
      paddingRight: 10,
    },
    '@media (min-width: 991px)': {
      paddingBottom: 10,
    },
    '&&:last-child': {
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
})(Link);

const AdditionalLinksRow = styled.div`
  margin: 0;
  @media (max-width: 991px) {
    padding-left: 22px;
    padding-top: 4px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
  }
`;

const AdditionalLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    width: 111px;
  }
  @media (max-width: 991px) {
    flex-direction: row;
  }
`;

const AdditionalLinksWrapper = ({ children }) => {
  return (
    <AdditionalLinksRow className="row">
      <div className="col-lg-0 col-md-4 col-sm-3 col-0" />
      <div className="col-lg-12 col-md-8 col-sm-9 col-12 padding-0">
        <div className="d-inline-block">
          <AdditionalLinksContainer>{children}</AdditionalLinksContainer>
        </div>
      </div>
    </AdditionalLinksRow>
  );
};

const ChangeObjectButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px
  margin-right: 16px
  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

const I18nPath = 'activerecord.attributes.inspection_finding';
const objectTypesI18nPath = 'inspections.inspections.form.investigable_other';
const pascalToSnakeCase = text => {
  return text
    .replace(/[A-Z]/g, c => {
      return `_${c.toLowerCase()}`;
    })
    .replace(/^_/, '');
};

class ObjectSelector extends Component {
  apiPathsForEquipment = {
    Equipment: 'wcmcEquipments',
    PfE: 'pfEs',
  };

  apiPathsForLocation = {
    Lab: 'labs',
    BuildingsFloor: 'buildings_floors',
    Building: 'buildings',
    Campus: 'campuses',
  };

  isFinalized = () => {
    return this.props.values.status === 'finalized';
  };

  objectButton = objectType => {
    const { setFieldValue, values, customInvestigableTypesNames } = this.props;
    const defaultOnPress = () => {
      if (['Equipment', 'Location'].includes(objectType)) {
        setFieldValue('object_type', 'Location');
        setFieldValue('investigable_type', null);
        setFieldValue('investigable_id', null);
        setFieldValue(`show${objectType}Selector`, true);
      } else {
        setFieldValue('object_type', objectType);
        setFieldValue('investigable_type', objectType);
        setFieldValue('investigable_id', null);
      }
    };

    const objectTypes = values.available_object_types;

    const canShowObjectButton = !objectTypes || objectTypes.includes(objectType);

    const customResourceName =
      pascalToSnakeCase(objectType) in customInvestigableTypesNames
        ? customInvestigableTypesNames[pascalToSnakeCase(objectType)]
        : null;

    if (canShowObjectButton) {
      return (
        <PrimaryButton
          disabled={this.isFinalized()}
          secondary
          className="mr-3 flex-grow-1"
          size="sm"
          onPress={defaultOnPress}
        >
          {I18n.t(`${objectTypesI18nPath}.${pascalToSnakeCase(objectType)}`, {
            resource_name: customResourceName,
          })}
        </PrimaryButton>
      );
    }

    return null;
  };

  selectObjectButton = () => {
    const { errors, touched, required } = this.props;

    return (
      <>
        <div className="form-default">
          <div className="row form-group mb-0 mt-4">
            <div className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label pr-0">
              <span>
                {t('title')}
                <span className={`${required ? 'with-asterisk' : ''}`} />
              </span>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-9 col-xs-12">
              <div className="d-flex flex-row">
                {this.objectButton('PermitsFdny')}
                {this.objectButton('Equipment')}
                {this.objectButton('ConstructionProject')}
              </div>
              <div className="d-flex flex-row mt-2">
                {this.objectButton('BiologicalSafety')}
                {this.objectButton('Location')}
                {this.objectButton('Department')}
              </div>
              <div className="d-flex flex-row mt-2">
                {this.objectButton('SafeWorkAuthorization')}
                {this.objectButton('Incident')}
                {this.objectButton('Accident')}
              </div>
              <div className="d-flex flex-row mt-2">
                {this.objectButton('RadInventoryItem')}
                {this.objectButton('RadLicense')}
                {this.objectButton('RadPurchase')}
              </div>
              <div className="d-flex flex-row mt-2">{this.objectButton('RadPermit')}</div>
              {errors && (
                <ErrorMessage>{getFormikErrors('investigable_id', touched, errors)}</ErrorMessage>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  resetSnapshot = () => {
    const { setFieldValue } = this.props;
    setFieldValue('has_investigable_snapshot', false);
    setFieldValue('refresh_investigable_snapshot', true);
  };

  changeObjectButton = () => {
    const { setFieldValue } = this.props;
    return (
      <ChangeObjectButtonWrapper>
        <PrimaryButton
          secondary
          disabled={this.isFinalized()}
          size="sm"
          expandableOnMobile={false}
          onPress={() => {
            setFieldValue('object_type', null);
            setFieldValue('investigable_type', null);
            setFieldValue('investigable.equipment_type_id', null);
            setFieldValue('investigable_id', null);
            setFieldValue('showEquipmentSelector', null);
            setFieldValue('showLocationSelector', null);
            this.resetSnapshot();
          }}
        >
          {t('change')}
        </PrimaryButton>
      </ChangeObjectButtonWrapper>
    );
  };

  viewObjectLink = () => {
    const { investigable_id, investigable_type, investigable } = this.props.values;
    if (investigable) {
      const { url, type, id } = investigable;
      if (id === investigable_id && type === investigable_type && url) {
        return (
          <StyledLink href={url} target="_blank" rel="noreferrer noopener">
            {t('view')}
          </StyledLink>
        );
      }
    }
    return null;
  };

  refreshSnapshotLink = () => {
    const {
      values: { capture_investigable_snapshot_enabled },
    } = this.props;
    const useSnapshot = this.shouldUseSnapshot();

    if (useSnapshot) {
      return (
        <StyledLink component="button" onClick={this.resetSnapshot}>
          {capture_investigable_snapshot_enabled ? t('refresh_snapshot') : t('delete_snapshot')}
        </StyledLink>
      );
    }
    return null;
  };

  shouldUseSnapshot = () => {
    const { values } = this.props;
    const { has_investigable_snapshot, refresh_investigable_snapshot } = values;

    return has_investigable_snapshot && !refresh_investigable_snapshot;
  };

  getSnapshotInputProps = () => {
    const {
      values: {
        investigable_snapshot_select_label: value,
        investigable_snapshot_datetime: datetime,
      },
    } = this.props;

    return {
      value,
      additionalInformation: (
        <span>
          {t('snapshot_datetime_prompt', { snapshot_datetime: dateFormatter.wordy(datetime) })}
          <InputLabelHint hint={t('snapshot_hint')} />
        </span>
      ),
    };
  };

  permitSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_permit`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'permits.index',
                intitialSelectionApiPath: 'permits.show',
                handleChange: value => setFieldValue('investigable_id', value),
                mapResult: item => ({
                  value: item.id,
                  id: item.id,
                  label: item.text,
                  header: item.header,
                  details: item.details,
                  responsiblePersonId: item.responsible_person_id,
                }),
                setSelectedRecord: value => {
                  if (!values.responsible_person_id && value.responsiblePersonId) {
                    setFieldValue('responsible_person_id', value.responsiblePersonId);
                  }
                },
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  constructionProjectSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_construction_project`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'constructionProjects.index',
                intitialSelectionApiPath: 'constructionProjects.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  biologicalSafetySelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_biological_safety`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'biologicalSafeties.index',
                intitialSelectionApiPath: 'biologicalSafeties.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  equipmentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched } = this.props;
    const { investigable, investigable_id, investigable_type } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: '' }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized() || !investigable_type,
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: `${this.apiPathsForEquipment[investigable_type]}.index`,
                requestParams: { type: investigable.equipment_type_id },
                intitialSelectionApiPath: `${this.apiPathsForEquipment[investigable_type]}.show`,
                handleChange: value => setFieldValue('investigable_id', value),
                mapResult: item => ({
                  value: item.id,
                  id: item.id,
                  label: item.text,
                  header: item.header,
                  details: item.details,
                  responsiblePersonId: item.responsible_person_id,
                  pfeLocId: item.pfe_loc_id,
                }),
                setSelectedRecord: value => {
                  if (!values.responsible_person_id && value.responsiblePersonId) {
                    setFieldValue('responsible_person_id', value.responsiblePersonId);
                  }
                  if (value.pfeLocId) {
                    setFieldValue('pfe_loc_id', value.pfeLocId);
                  }
                },
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  equipmentTypeSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_equipment`), required }}
        inputProps={{
          disabled: useSnapshot || this.isFinalized(),
          setFieldTouched,
          value: values.investigable_type,
          name: 'investigable_type',
          initialSelection: values.investigable_type,
          handleChange: value => {
            setFieldValue('investigable_type', value);
            setFieldValue('investigable.equipment_type_id', null);
            setFieldValue('investigable_id', null);
          },
          options: () => {
            return {
              options: [
                {
                  id: 'Equipment',
                  value: 'Equipment',
                  header: I18n.t(`${I18nPath}.object.equipment.general_equipment`),
                },
                {
                  id: 'PfE',
                  value: 'PfE',
                  header: I18n.t(`${I18nPath}.object.equipment.pf_e`),
                },
              ],
              hasMore: false,
            };
          },
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_type', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>{this.changeObjectButton()}</AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  equipmentSubtypeSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched } = this.props;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        labelProps={{ label: I18n.t(`${I18nPath}.equipment_type`) }}
        inputProps={{
          disabled: useSnapshot || this.isFinalized() || !values.investigable_type,
          setFieldTouched,
          value: values.investigable.equipment_type_id,
          initialSelection: values.investigable.equipment_type_id,
          apiPath: 'equipmentTypes.index',
          intitialSelectionApiPath: 'equipmentTypes.show',
          handleChange: value => {
            setFieldValue('investigable.equipment_type_id', value);
            setFieldValue('investigable_id', null);
          },
          mapResult: item => ({
            value: item.id,
            id: item.id,
            label: item.text,
            header: item.text,
          }),
        }}
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable.equipment_type_id', touched, errors)}
        type={INPUT_TYPES.ASYNC_SELECT}
      />
    );
  };

  equipmentSelectorWrapper = () => {
    const { values } = this.props;
    return (
      <>
        {this.equipmentTypeSelector()}
        {values.investigable_type === 'Equipment' && this.equipmentSubtypeSelector()}
        {(values.investigable_type === 'PfE' || get(values, 'investigable.equipment_type_id')) &&
          this.equipmentSelector()}
      </>
    );
  };

  locationSelectorWrapper = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id, investigable_type } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <>
        <SaluteInputField
          childrenWrapperClassName="col-lg-3 col-12 padding-0"
          labelProps={{ label: I18n.t(`${I18nPath}.object_location`), required }}
          inputProps={{
            disabled: useSnapshot || this.isFinalized(),
            setFieldTouched,
            value: values.investigable_type,
            name: 'investigable_type',
            initialSelection: values.investigable_type,
            handleChange: value => {
              setFieldValue('investigable_type', value);
              setFieldValue('investigable_id', null);
            },
            options: () => {
              return {
                options: [
                  {
                    id: 'Campus',
                    value: 'Campus',
                    header: I18n.t(`${I18nPath}.object.location.campus`),
                  },
                  {
                    id: 'Building',
                    value: 'Building',
                    header: I18n.t(`${I18nPath}.object.location.building`),
                  },
                  {
                    id: 'BuildingsFloor',
                    value: 'BuildingsFloor',
                    header: I18n.t(`${I18nPath}.object.location.buildings_floor`),
                  },
                  {
                    id: 'Lab',
                    value: 'Lab',
                    header: I18n.t(`${I18nPath}.object.location.lab`),
                  },
                ],
                hasMore: false,
              };
            },
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('investigable_type', touched, errors)}
          type={INPUT_TYPES.ASYNC_SELECT}
        >
          <AdditionalLinksWrapper>{this.changeObjectButton()}</AdditionalLinksWrapper>
        </SaluteInputField>
        <SaluteInputField
          childrenWrapperClassName="col-lg-3 col-12 padding-0"
          labelProps={{ label: '' }}
          inputProps={
            useSnapshot
              ? this.getSnapshotInputProps()
              : {
                  disabled: this.isFinalized() || !investigable_type,
                  setFieldTouched,
                  value: investigable_id,
                  name: 'investigable_id',
                  initialSelection: investigable_id,
                  apiPath: `${this.apiPathsForLocation[investigable_type]}.index`,
                  intitialSelectionApiPath: `${this.apiPathsForLocation[investigable_type]}.show`,
                  handleChange: value => setFieldValue('investigable_id', value),
                  mapResult: item => ({
                    value: item.id,
                    id: item.id,
                    label: item.text,
                    header: item.header + (item.active ? '' : ' (inactive)'),
                    details: item.details,
                    responsiblePersonId: item.responsible_person_id,
                  }),
                  setSelectedRecord: value => {
                    if (!values.responsible_person_id && value.responsiblePersonId) {
                      setFieldValue('responsible_person_id', value.responsiblePersonId);
                    }
                  },
                }
          }
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('investigable_id', touched, errors)}
          type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
        >
          <AdditionalLinksWrapper>
            {this.viewObjectLink()}
            {this.refreshSnapshotLink()}
          </AdditionalLinksWrapper>
        </SaluteInputField>
      </>
    );
  };

  departmentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_department`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'departments.index',
                intitialSelectionApiPath: 'departments.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  safeWorkAuthorizationSelector = () => {
    const {
      values,
      errors,
      setFieldValue,
      touched,
      setFieldTouched,
      required,
      customInvestigableTypesNames,
    } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{
          label: I18n.t(`${I18nPath}.object_safe_work_authorization`, {
            resource_name: customInvestigableTypesNames.safe_work_authorization,
          }),
          required,
        }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'safe_work_authorizations.index',
                intitialSelectionApiPath: 'safe_work_authorizations.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  incidentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_incident`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'incidents.index',
                intitialSelectionApiPath: 'incidents.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  accidentSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_accident`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'accidents.index',
                intitialSelectionApiPath: 'accidents.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  radInventoryItemSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_rad_inventory_item`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'rad_inventory_items.index',
                intitialSelectionApiPath: 'rad_inventory_items.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  radLicenseSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_rad_license`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'rad_licenses.index',
                intitialSelectionApiPath: 'rad_licenses.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  radPurchaseSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_rad_purchase`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'rad_purchases.index',
                intitialSelectionApiPath: 'rad_purchases.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  radPermitSelector = () => {
    const { values, errors, setFieldValue, touched, setFieldTouched, required } = this.props;
    const { investigable_id } = values;
    const useSnapshot = this.shouldUseSnapshot();

    return (
      <SaluteInputField
        childrenWrapperClassName="col-lg-3 col-12 padding-0"
        labelProps={{ label: I18n.t(`${I18nPath}.object_rad_permit`), required }}
        inputProps={
          useSnapshot
            ? this.getSnapshotInputProps()
            : {
                disabled: this.isFinalized(),
                setFieldTouched,
                value: investigable_id,
                name: 'investigable_id',
                initialSelection: investigable_id,
                apiPath: 'rad_permits.index',
                intitialSelectionApiPath: 'rad_permits.show',
                handleChange: value => setFieldValue('investigable_id', value),
              }
        }
        fieldWidth={FIELD_WIDTHS.WIDE}
        error={getFormikErrors('investigable_id', touched, errors)}
        type={useSnapshot ? INPUT_TYPES.HTML_PRESENTER : INPUT_TYPES.ASYNC_SELECT}
      >
        <AdditionalLinksWrapper>
          {this.changeObjectButton()}
          {this.viewObjectLink()}
          {this.refreshSnapshotLink()}
        </AdditionalLinksWrapper>
      </SaluteInputField>
    );
  };

  showEquipmentSelector = () => {
    const { values } = this.props;

    return values.showEquipmentSelector || ['Equipment', 'PfE'].includes(values.investigable_type);
  };

  showLocationSelector = () => {
    const { values } = this.props;

    return (
      values.showLocationSelector ||
      ['Campus', 'Building', 'BuildingsFloor', 'Lab'].includes(values.investigable_type)
    );
  };

  render() {
    const {
      values: { investigable_type },
    } = this.props;

    if (!investigable_type && !this.showEquipmentSelector() && !this.showLocationSelector()) {
      return this.selectObjectButton();
    }
    if (this.showEquipmentSelector()) return this.equipmentSelectorWrapper();
    if (this.showLocationSelector()) return this.locationSelectorWrapper();
    switch (investigable_type) {
      case 'PermitsFdny':
        return this.permitSelector();
      case 'ConstructionProject':
        return this.constructionProjectSelector();
      case 'BiologicalSafety':
        return this.biologicalSafetySelector();
      case 'Department':
        return this.departmentSelector();
      case 'SafeWorkAuthorization':
        return this.safeWorkAuthorizationSelector();
      case 'Incident':
        return this.incidentSelector();
      case 'Accident':
        return this.accidentSelector();
      case 'RadInventoryItem':
        return this.radInventoryItemSelector();
      case 'RadLicense':
        return this.radLicenseSelector();
      case 'RadPurchase':
        return this.radPurchaseSelector();
      case 'RadPermit':
        return this.radPermitSelector();
      default:
        return null;
    }
  }
}

ObjectSelector.propTypes = {
  required: bool,
};

ObjectSelector.defaultProps = {
  required: true,
};

export default ObjectSelector;
