import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { Html5Qrcode } from 'html5-qrcode';

import { QrCodeIcon } from '../../../stateless_components/icons/QrCodeIcon';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { colors } from '../../assets/styles/colors';
import { Modal } from '../modals/Modal';
import { FieldWrapper } from './auxiliary/FieldWrapper';

export const StyledScanButton = styled.button`
  padding: 0.25rem 1rem;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:focus,
  &:hover {
    background: ${colors.inputBorderColor};
  }
`;

export const StyledScanButtonTitle = styled.span`
  padding-left: 10px;
`;

export const StyledSuccessIcon = styled.i`
  color: #6fc528;
`;

const t = (key, options) => I18n.t(`qr_code_verification.${key}`, options);

const YES_ANSWER = '1';
const NO_ANSWER = '2';
const NA_ANSWER = '3';

const ModalContent = props => {
  const { onQrCodeFound, onScanningError, modalRef } = props;
  const [qrCodeReader, setQrCodeReader] = useState();

  const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };

  const handleStop = () => {
    try {
      qrCodeReader
        .stop()
        .then(_res => {
          qrCodeReader.clear();
        })
        .catch(err => {
          onScanningError(err);
        });
      modalRef.current.hide();
    } catch (err) {
      onScanningError(err);
    }
  };

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, _decodedResult) => {
      onQrCodeFound(decodedText);
      handleStop();
    };
    qrCodeReader
      .start({ facingMode: 'environment' }, qrConfig, qrCodeSuccessCallback)
      .catch(err => {
        onScanningError(err);
      });
  };

  useEffect(() => {
    setQrCodeReader(new Html5Qrcode('qr-code-reader'));
    const oldRegion = document.getElementById('qr-shaded-region');
    if (oldRegion) {
      oldRegion.remove();
    }
  }, []);

  useEffect(() => {
    if (qrCodeReader) {
      handleClickAdvanced();
    }
  }, [qrCodeReader]);

  return <div id="qr-code-reader" width="100%" />;
};

export const CodeScannerField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    resourceName,
    id,
    domainName,
    objectVerified,
  } = props;

  const inputId = id || `${resourceName}-${name}`;
  const [scanningEnabled, setScanningEnabled] = useState(false);
  const [scanningError, setScanningError] = useState(false);
  const modalRef = useRef(null);

  const obtainIdFromApiUrl = async url => {
    const regex = new RegExp(`^(https|http):\\/\\/${domainName}\\/go\\/\\d+$`);

    if (url.match(regex)) {
      const response = await fetch(url, { method: 'GET', redirect: 'follow' });
      return response.url.match(/[0-9]+\/edit/)[0].split('/')[0];
    }
    return 'WRONG QR CODE';
  };

  const defaultOnChange = async newValue => {
    await setScanningEnabled(false);
    await setFieldTouched(name, true);
    await setFieldValue(name, newValue);
  };

  const onQrCodeCodeFound = async e => {
    const obtainedId = await obtainIdFromApiUrl(e);
    if (onChange) {
      await onChange(obtainedId, defaultOnChange);
    } else {
      await defaultOnChange(obtainedId);
    }
  };

  const openModal = async () => {
    await modalRef.current.open();
    await setScanningEnabled(true);
  };

  const closeModal = async () => {
    await modalRef.current.hide();
    await setScanningEnabled(false);

    if (onBlur) {
      await onBlur();
    } else {
      await setFieldTouched(name, true);
    }
  };

  const onScanningError = async e => {
    await setScanningError(e);
    await closeModal();
  };

  const humanValue = () => {
    switch (value) {
      case YES_ANSWER:
        return (
          <>
            <StyledSuccessIcon className="fas fa-check-circle" />
            &nbsp;
            {t('qr_code_verified', { object: objectVerified })}
          </>
        );
      case NO_ANSWER:
        return (
          <span className="icon-warning-2 text-danger">
            &nbsp;
            {t('qr_code_not_verified', { object: objectVerified })}
          </span>
        );
      case NA_ANSWER:
        return <span style={{ fontSize: '14px' }}>{t('wrong_object')}</span>;
      default:
        return '';
    }
  };

  return (
    <Box className="w-100">
      <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
        <Modal title={t('modal_title')} ref={modalRef} onHide={() => setScanningEnabled(false)}>
          <ModalContent
            onQrCodeFound={onQrCodeCodeFound}
            onScanningError={onScanningError}
            modalRef={modalRef}
          />
        </Modal>
        {value !== YES_ANSWER && !scanningEnabled && (
          <StyledScanButton onClick={openModal} disabled={disabled}>
            <QrCodeIcon fontSize={24} />
            <StyledScanButtonTitle>{t('enable_button')}</StyledScanButtonTitle>
          </StyledScanButton>
        )}
        {scanningEnabled && (
          <StyledScanButton onClick={closeModal} disabled={disabled}>
            <QrCodeIcon fontSize={24} />
            <StyledScanButtonTitle>{t('disable_button')}</StyledScanButtonTitle>
          </StyledScanButton>
        )}
        {value !== null && <StyledScanButtonTitle>{humanValue()}</StyledScanButtonTitle>}
      </FieldWrapper>
      {scanningError && <div style={{ fontSize: '14px' }}>{scanningError}</div>}
    </Box>
  );
};
